import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/connect";

const Accessories = ({ initialValue, onSelected }) => {
  const [selectedAccessories, setSelectedAccessories] = useState(initialValue || null); // Initialize with initialValue
  const [variations, setVariations] = useState([]); // Store variations of accessories
  const { prodcode } = useParams(); // Extract product code from URL params

  // Fetch product accessories variations
  const getAllProductVariations = async () => {
    try {
      const response = await fetch(
        `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=fashionvariations&prcode=${prodcode}`
      );
      const json = await response.json();
      const firstResult = json["results"][0];
      if (firstResult && firstResult.accessories) {
        const accessoriesObject = firstResult.accessories;
        if (accessoriesObject.trim() !== "") {
          setVariations(accessoriesObject.split(","));
        } else {
          setVariations([]);
        }
      }
    } catch (error) {
      console.error("Error fetching variations:", error); // Log error for debugging
      setVariations([]);
    }
  };

  useEffect(() => {
    getAllProductVariations();
  }, [prodcode]);

  // Update state when initialValue changes
  useEffect(() => {
    if (initialValue) {
      setSelectedAccessories(initialValue);
    }
  }, [initialValue]);

  // Handle accessory selection toggle
  const handleAccessoriesClick = (accessory) => {
    const newSelected = selectedAccessories === accessory ? null : accessory;
    setSelectedAccessories(newSelected);
    onSelected(newSelected); // Notify parent component
  };

  return variations.length > 0 ? (
    <div className="sizes-container" style={{ display: "flex", flexDirection: "row" }}>
      <span style={{ fontWeight: "bold", color: "black", marginTop: "12px" }}>
        Accessories:
      </span>
      <div className="sizes" style={{ display: "flex", gap: "8px" }}>
        {variations.map((variation, i) => (
          <div
            key={i}
            className={`size ${selectedAccessories === variation ? "selected" : ""}`}
            style={{
              padding: "8px 12px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: selectedAccessories === variation ? "#FF4800" : "#f9f9f9",
              color: selectedAccessories === variation ? "#fff" : "#000",
            }}
            onClick={() => handleAccessoriesClick(variation)}
          >
            {variation}
          </div>
        ))}
      </div>
    </div>
  ) : null; // Return null if no variations available
};

export default Accessories;
